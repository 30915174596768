<template>
  <div>
    <ds-box-information
      v-if="showBoxResendCodeInformation"
      theme="info"
      title="Reenviar Código"
      message="Insira sua senha novamente para receber um novo SMS">
    </ds-box-information>
    <br />
    <ds-form
      :submit-action="onSubmit"
      :error-message="errorMessage"
      @submit-error="onSubmitError"
      @submit-success="onSubmitSuccess">
      <ds-row>
        <ds-col>
          <ds-field label="E-mail">
            <ds-email-input v-model="email" required />
          </ds-field>
        </ds-col>
      </ds-row>
      <ds-row>
        <ds-col>
          <ds-field label="Senha">
            <ds-input v-model="password" type="password" required />
          </ds-field>
        </ds-col>
      </ds-row>
      <ds-row>
        <ds-col>
          <div class="login__forgot-password-link">
            <ds-link
              ref="recoverPassword"
              href="#/recuperar-senha"
              @click="trackOnClick('clickedLinkToRecoverPassword')">
              Esqueci minha senha
            </ds-link>
          </div>
        </ds-col>
      </ds-row>
      <ds-row>
        <ds-col>
          <ds-submit-button full-width> Entrar </ds-submit-button>
        </ds-col>
      </ds-row>
      <ds-row>
        <ds-col class="ds-u-text-align--center">
          <ds-link
            href="https://experimente.contaazul.com/cadastro"
            @click="trackOnClick('clickedLinkToCreateNewAccount')">
            Não tem uma conta? Experimente grátis!
          </ds-link>
        </ds-col>
      </ds-row>
      <ds-row vertical-offset="3">
        <ds-col>
          <ds-text>É de uma empresa de contabilidade? </ds-text>
          <ds-link href="https://contabilidade.contaazul.com" @click="trackOnClick('clickedLinkToGoToContaAzulMais')">
            Acesse a Conta Azul Mais aqui
          </ds-link>
        </ds-col>
      </ds-row>
    </ds-form>
  </div>
</template>

<script>
import { sanitizeUrl } from '@contaazul/sanitize-url';
import getRequestErrorMessage from '@/services/requestErrorMessage';
import track from '@/services/trackService';
import TRACKS_EVENTS from '@/services/trackConstants';
import redirectToProduct from '@/services/redirectToProduct';
import { clearSession } from '@/resources/clearSessionAndCookies';
import { hasCognitoAuth } from '@/services/cognitoService';
import { requestLogin } from '@/resources/login';
import to from 'await-to-js';
import { signInAndRedirect } from '@/services/signInService';
import { Buffer } from 'buffer';
import {
  triedToLoginTrack,
  successLoginTrack,
  errorLoginTrack,
} from '@/services/authenticationTrack/authenticationTrackService';
import { REQUEST_LOGIN_ERROR, REQUEST_LOGIN_ERROR_CODE } from './requestMessages';
import { EMAIL_ORIGIN_RESEND_CODE } from '../../utils/constants';

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      errorMessage: null,
      redirect: '',
      isCognito: null,
      showBoxResendCodeInformation: false,
    };
  },
  created() {
    this.fillEmail();
  },
  methods: {
    fillEmail() {
      if (this.$route.params.emailOrigin === EMAIL_ORIGIN_RESEND_CODE) {
        this.showBoxResendCodeInformation = true;
        this.email = this.$route.params.email;
      }
    },
    async onSubmit() {
      track({
        ...TRACKS_EVENTS.loginFormSubmitted,
        properties: {
          email: this.email,
        },
      });

      this.errorMessage = null;

      this.isCognito = await hasCognitoAuth(this.email);

      triedToLoginTrack({
        email: this.email,
        isCognito: this.isCognito,
      });

      return this.isCognito
        ? signInAndRedirect(this.email, this.password, null)
        : requestLogin({
            email: this.email,
            password: this.password,
          });
    },
    onSubmitError(error) {
      if (!this.isCognito) {
        errorLoginTrack({
          email: this.email,
          isCognito: false,
          properties: {
            step: 'authenticate',
            error: {
              code: error?.status,
              message: error?.message,
            },
          },
        });
      }

      if (error?.code === REQUEST_LOGIN_ERROR_CODE.USER_NOT_CONFIRMED_EXCEPTION) {
        this.$router.push({
          name: 'MFASignup',
          query: {
            email: Buffer.from(this.email).toString(`base64`),
          },
        });
        return;
      }

      this.errorMessage = getRequestErrorMessage(error, REQUEST_LOGIN_ERROR.GENERIC_FAIL);
    },
    async onSubmitSuccess(data) {
      this.redirect = this.$route?.query?.redirect || '';
      track({
        ...TRACKS_EVENTS.userLoggedSuccessfully,
        properties: {
          email: this.email,
          isCognito: this.isCognito,
        },
      });

      if (data?.challengeName === 'SMS_MFA' || data?.challengeName === 'SOFTWARE_TOKEN_MFA') {
        successLoginTrack({
          email: this.email,
          isCognito: true,
          properties: {
            step: 'mfaConfirmation',
            data: data.challengeName,
          },
        });

        this.$router.push({
          name: 'MFASignin',
          query: {
            email: Buffer.from(data.username).toString(`base64`),
            phone: data?.challengeParam?.CODE_DELIVERY_DESTINATION
              ? Buffer.from(data.challengeParam.CODE_DELIVERY_DESTINATION).toString(`base64`)
              : '',
            redirect: this.redirect,
          },
          params: { user: data },
        });
        return;
      }

      if (data?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        successLoginTrack({
          email: this.email,
          isCognito: true,
          properties: {
            step: 'forceChangePassword',
          },
        });

        this.$router.push({
          name: 'PasswordRequired',
          params: { user: data },
        });
        return;
      }

      if (data?.hasRequiredTotpConfiguration) {
        successLoginTrack({
          email: this.email,
          isCognito: true,
          properties: {
            step: 'requiredTOTPConfiguration',
          },
        });

        this.$router.push({
          name: 'MfaTotpConfiguration',
          query: {
            redirect: this.redirect,
          },
          params: { email: this.email },
        });
        return;
      }

      successLoginTrack({
        email: this.email,
        isCognito: this.isCognito,
        properties: {
          step: 'redirectToApp',
          jwt: data?.jwt,
        },
      });

      if (this.redirect) {
        window.location.href = sanitizeUrl(this.redirect);
        return;
      }

      if (data?.location) {
        window.location.href = sanitizeUrl(data?.location);
        return;
      }

      await to(clearSession());

      redirectToProduct(this.$route);
    },
    trackOnClick(eventName) {
      track(TRACKS_EVENTS[eventName]);
    },
  },
};
</script>

<style scoped></style>
